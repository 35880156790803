<script lang="ts" setup>
import Loading from './Loading.vue';

const props = defineProps<{
  title: string;
}>();
</script>

<template>
  <UCard class="flex flex-col items-center min-w-[20rem] h-[15rem] justify-center py-6 px-10">
    <template #header>
      <span class="text-2xl font-semibold">
        {{ props.title }}
      </span>
    </template>

    <Loading class="w-20 h-20" />
  </UCard>
</template>
